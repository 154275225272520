import React from 'react'
import type { BrowserHistory } from 'history'
import { useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom'

interface HistoryRouterProps {
	history: BrowserHistory
	basename?: string
	children?: React.ReactNode
}

const HistoryRouter = ({ basename, children, history }: HistoryRouterProps) => {
	const [state, setState] = useState({
		action: history.action,
		location: history.location,
	})

	useLayoutEffect(() => history.listen(setState), [history])

	return (
		<Router
			basename={basename}
			location={state.location}
			navigationType={state.action}
			navigator={history}
		>
			{children}
		</Router>
	)
}

export default HistoryRouter
